(function () {
	'use strict';

	angular
		.module('measuring')
		.directive('chart', [
			'$translate',
			'seedcodeCalendar',
			'utilities',
			chart,
		])
		.directive('navigateBreakout', ['utilities', navigateBreakout])
		.directive('measureBreakout', ['$timeout', measureBreakout])
		.directive('footerBreakoutItem', ['$translate', footerBreakoutItem]);

	function chart($translate, seedcodeCalendar, utilities) {
		return {
			restrict: 'EA',
			scope: {
				data: '=data',
				options: '=options',
				trigger: '=trigger',
			},
			link: function (scope, element, attrs) {
				var isBackgroundChart = attrs.backgroundChart;
				var translations = $translate.instant([
					'Threshold',
					'Over by',
					'Includes partial week',
					'Includes partial month',
					'over',
					'hour',
					'hours',
					'day',
					'days',
					'week',
					'weeks',
					'month',
					'months',
					'year',
					'years',
				]);

				//Start link
				scope.$watch('trigger', function (newValue, oldValue) {
					createChart(scope.data, scope.options);
				});

				// On scope destroy
				scope.$on('$destroy', function (e) {
					// When we destroy the measure element and scope then remove the chart tooltip
					if (
						Chartist.thresholdTooltip &&
						Chartist.thresholdTooltip.destroy
					) {
						Chartist.thresholdTooltip.destroy();
					}
				});

				function createChart(data, options) {
					var duration = options.chart.visibleChartPointCount;
					var rangeType = options.chart.rangeType;
					var partialRange = options.chart.partialRange;
					var rangeTypeDisplay = rangeType;
					// Convert range type to plural if we have more than 1 in duration
					if (duration !== 1) {
						rangeTypeDisplay += 's';
					}

					var value = '';
					if (isBackgroundChart) {
						options.plugins = null;
					} else {
						options.plugins = [
							// Chartist.plugins.tooltip({
							//   appendToBody: true,
							//   // pointClass: 'my-cool-point'
							// }),
						];

						// Create threshold line tooltip content
						if (options.chart.threshold) {
							value +=
								'<div>' +
								'<div>' +
								'<span class="threshold-title-label label-after-value label-long">' +
								translations['Threshold'] +
								': ' +
								'</span>' +
								'<span class="threshold-display">' +
								options.chart.thresholdFormatted +
								'</span>' +
								'</div>';
							if (rangeType !== 'resource') {
								value +=
									'<div class="threshold-total">' +
									options.chart.thresholdTotalDisplay;
								value +=
									'<span class=""> ' +
									translations['over'] +
									' ' +
									duration +
									' ' +
									translations[rangeTypeDisplay] +
									'</span>';
							}
							value += '</div>';

							if (partialRange) {
								value +=
									'<div class="estimated-count" style="margin-top: 5px">*' +
									translations[
									'Includes partial ' + rangeType
									] +
									'</div>';
							}

							value += '</div>';

							options.plugins.push(
								Chartist.plugins.ctThreshold({
									threshold: options.chart.threshold,
									thresholdLineColor:
										options.chart.thresholdLineColor,
									thresholdFillColor:
										options.chart.thresholdFillColor,
									thresholdFillOpacity:
										options.chart.thresholdFillOpacity,
									thresholdDisplay: value,
								})
							);
						}
					}
					// Create a new line chart object where as first parameter we pass in a selector
					// that is resolving to our chart container element. The Second parameter
					// is the actual data object.
					var chart = new Chartist.Line(
						element[0],
						data.chartData,
						options
					);
					// console.log('colContentRight', view.colContentRight());
					// var chartElement = document.querySelector('.chart-container');
					// console.log('element', chartElement);
					element[0].style.marginLeft = options.chart.left;

					// chart.container.style.marginLeft = options.left;
					// chartElement.style.width = (chartElement.style.width.offsetWidth - view.colLeft(0)) + 'px';
					chart.on('created', function (context) {
						//Move point to middle of column
						// var chartPoints =
						// 	context.svg.querySelectorAll('.ct-series');
						// for (
						// 	var i = 0;
						// 	i < chartPoints.svgElements.length;
						// 	i++
						// ) {
						// 	chartPoints.svgElements[i].attr({
						// 		transform:
						// 			'translate(' +
						// 			context.axisX.stepLength / 2 +
						// 			')',
						// 	});
						// }
					});

					// This is the bit we are actually interested in. By registering a callback for `draw` events, we can actually intercept the drawing process of each element on the chart.
					chart.on('draw', function (context) {
						var propertyString = '';
						var parent;
						if (context.type === 'area') {
							//If the chart needs to be offset from the starting point
							if (context.series[0].offset) {
								parent = context.element.parent();
								parent.attr({
									style:
										'transform: translate(' +
										context.series[0].offset +
										'px)',
								});
							}
						}

						if (
							!options.chart.useBreakoutColors ||
							isBackgroundChart
						) {
							if (propertyString) {
								context.element.attr({
									style: propertyString,
								});
							}
							return;
						}

						var index = context.index;
						var color = isBackgroundChart
							? 'rgb(255,255,255)'
							: context.series[0].color;
						// var backgroundColor = utilities.generateColorTransparency(color, 0.25);

						if (context.type === 'point' && !isBackgroundChart) {
							var seriesData = context.series[context.index];
							var label = seriesData.label
								? seriesData.label + ': '
								: '';
							var value =
								label +
								context.series[context.index].valueFormatted; //context.value.y;

							if (seriesData.isEstimate) {
								value = value + '*';
							}
							if (
								context.series[context.index]
									.overThresholdFormatted
							) {
								value =
									value +
									'<div class="over-threshold">' +
									translations['Over by'] +
									' ' +
									context.series[context.index]
										.overThresholdFormatted +
									'</div>';
							}
							if (context.series[context.index].dateString) {
								value =
									value +
									'<div class="date-string">' +
									context.series[context.index].dateString +
									'</div>';
							}
							if (seriesData.isEstimate) {
								value =
									value +
									'<div class="estimated-count">*' +
									translations[
									'Partial charting range shown'
									] +
									'</div>';
							}

							//Enable any tooltips
							context.element.attr({
								title: value,
							});

							if (context.index == context.series.length - 1) {
								$('.ct-point').tooltip({
									container: 'body',
									animation: false,
									trigger: 'hover',
									html: true,
									template:
										'<div class="tooltip tooltip-chart" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
								});
							}
							//
							// Destroy tooltip when closing popover
							// scope.$on('$destroy', function (e) {
							//   $('.edit-panel-default-' + scope.name).tooltip('destroy');
							// });

							// With the Chartist.Svg API we can easily set an attribute on our bar that just got drawn
							//context.x;
							// context.element.attr({
							//   transform: 'translate(' + context.x + ')'
							//   // transform: 'translate(' + context.axisX.stepLength + ')'
							// });
						}

						// Obtain a clean label
						var seriesData = context.series[context.index];
						let classList = 'ct-' + context.type;
						let classLabel = seriesData?.label || 'none';
						classLabel = utilities.stringToClass(classLabel, 'ct-resource');

						// First we want to make sure that only do something when the draw event is for bars. Draw events do get fired for labels and grids too.
						if (
							context.type === 'line' ||
							context.type === 'point'
						) {
							// console.log(context.series[context.index]);
							if (
								context.type === 'point' &&
								context.series[context.index].isPadding
							) {
								propertyString += 'visibility: hidden;';
							}
							// With the Chartist.Svg API we can easily set an attribute on our bar that just got drawn
							context.element.attr({
								// Now we set the style attribute on our bar to override the default color of the bar. By using a HSL colour we can easily set the hue of the colour dynamically while keeping the same saturation and lightness. From the context we can also get the current value of the bar. We use that value to calculate a hue between 0 and 100 degree. This will make our bars appear green when close to the maximum and red when close to zero.
								style:
									'stroke: ' + color + ';' + propertyString,
								class: classList + ' ' + classLabel
							});
						} else if (context.type === 'area') {
							context.element.attr({
								// Now we set the style attribute on our bar to override the default color of the bar. By using a HSL colour we can easily set the hue of the colour dynamically while keeping the same saturation and lightness. From the context we can also get the current value of the bar. We use that value to calculate a hue between 0 and 100 degree. This will make our bars appear green when close to the maximum and red when close to zero.
								style: 'fill: ' + color + ';' + propertyString,
								class: classList + ' ' + classLabel
							});
						}
					});
				}

				//End link
			},
		};
	}

	function navigateBreakout(utilities) {
		return {
			restrict: 'EA',
			scope: {
				position: '=',
				maxScroll: '=',
			},
			link: function (scope, element, attrs) {
				const direction = attrs.direction;
				let mouseDown = false;
				let lastScrollPosition = 0;

				let scrollElement;

				scope.position = 0;

				//Clean up external events on element destroy
				scope.$on('$destroy', function () {
					element[0].removeEventListener(
						'mousedown',
						activateMouseDown
					);
					element[0].removeEventListener(
						'mousedown',
						activateMouseDown
					);
				});

				element[0].addEventListener('mousedown', activateMouseDown);
				element[0].addEventListener('mouseup', activateMouseUp);

				function activateMouseDown(e) {
					mouseDown = true;
					activateScroll();
				}

				function activateMouseUp(e) {
					mouseDown = false;
				}

				function activateScroll() {
					if (!document.body.contains(scrollElement)) {
						scrollElement =
							document.querySelector('.breakout-totals');
					}
					let scrollPosition = scrollElement.scrollLeft;
					let position;
					if (
						!mouseDown ||
						(lastScrollPosition === scrollPosition &&
							lastScrollPosition > 0)
					) {
						mouseDown = false;
						return;
					}

					if (direction === 'left') {
						if (scrollPosition <= 0) {
							return;
						}
						position = scrollPosition - 50;
					} else {
						if (scrollPosition >= scope.maxScroll) {
							return;
						}
						position = scrollPosition + 50;
					}
					scope.$evalAsync(function () {
						scope.position = position;
					});

					lastScrollPosition = scrollElement.scrollLeft;

					if (mouseDown) {
						utilities.scrollToX(
							scrollElement,
							position,
							1000,
							'easeInOutQuint',
							null
						);

						window.setTimeout(function () {
							activateScroll();
						}, 50);
					}
				}
			},
			template: function (tElement, tAttributes) {
				const direction = tAttributes.direction;
				const templateLeft =
					'<div ng-show="maxScroll > 0" class="breakout-scroll breakout-scroll-left" ng-class="{\'disabled\': position <= 0}"><i class="fa fa-chevron-left fa-lg" aria-hidden="true"></i></div>';
				const templateRight =
					'<div ng-show="maxScroll > 0" class="breakout-scroll breakout-scroll-right" ng-class="{\'disabled\': position >= maxScroll}"><i class="fa fa-chevron-right fa-lg" aria-hidden="true"></i></div>';
				if (direction === 'left') {
					return templateLeft;
				} else {
					return templateRight;
				}
			},
		};
	}

	function measureBreakout($timeout) {
		return {
			restrict: 'EA',
			scope: {
				breakout: '=',
				measureData: '=',
				isPhone: '@',
			},
			link: function (scope, element, attrs) {
				var scrollElement = element.find('.breakout-totals');
				var totalContainerElement = element.find('.footer-total');
				var scrollWidth;
				var elementWidth;

				scope.breakoutContainerStyle = {};
				scope.totalContainerStyle = {};

				scope.posiiton = 0;

				scope.$on('breakoutChanged', function () {
					scope.totalContainerStyle.width = 0; //Set total container to 0 px so we can get an accurate scroll width when adding or removing characters
					$timeout(function () {
						updateScrollPosition();
					}, 0);
				});

				function updateScrollPosition() {
					//Update container width and position
					var containerScrollWidth =
						totalContainerElement[0].scrollWidth + 20;
					var totalCharacterCount =
						scope.measureData.totalCountFormatted.length;
					var totalContainerWidth = totalCharacterCount * 15 + 50;
					scope.totalContainerStyle.width =
						containerScrollWidth + 'px';
					scope.breakoutContainerStyle.right =
						containerScrollWidth + 60 + 'px';

					//Update scroll position
					if (scrollElement && scrollElement.length) {
						scrollWidth = scrollElement[0].scrollWidth;
						elementWidth = scrollElement[0].offsetWidth;
						scope.maxScroll = scrollWidth - elementWidth;
						if (scope.maxScroll === 0) {
							scope.position = 0;
						}
					}
				}
			},
			templateUrl: 'app/measure/breakout-footer.html',
		};
	}

	function footerBreakoutItem($translate) {
		return {
			restrict: 'EA',
			replace: true,
			// scope: {
			// 	breakout: '=',
			// },
			link: function (scope, element, attrs) {
				var translations = $translate.instant([
					'Threshold',
					'Over by',
					'Under by',
					'Total Available',
					'At threshold',
					'Includes partial week',
					'Includes partial month',
					'Over under threshold',
				]);
				// $translate(
				// 	[
				// 		'Subscription renews',
				// 		'Subscription renews today',
				// 		'Subscription should have renewed',
				// 	],
				// 	{days: dayString}
				// );

				var rangeType = scope.measureData.rangeType;
				var partialRange = scope.measureData.partialRange;
				var threshold = scope.measureData.threshold
					? Number(scope.measureData.threshold)
					: 0;
				var showThreshold = scope.measureData.showThreshold;
				var thresholdDisplay = scope.measureData.thresholdDisplay;

				var breakoutItem = scope.breakoutItem;
				var thresholdTotal = scope.measureData.thresholdTotal;
				var overThreshold = breakoutItem.thresholdRemaining < 0;
				var atThreshold = breakoutItem.thresholdRemaining === 0;
				var overThresholdPercent =
					Math.abs(breakoutItem.thresholdRemaining) / thresholdTotal;

				// Round percent to nearest 2 digits and convert to percent between 0 and 100%
				overThresholdPercent = Math.round(overThresholdPercent * 100);

				var thresholdDifferenceFormatted =
					breakoutItem.overThresholdFormatted;
				var value = '';

				value +=
					'<div class="">' +
					'<div>' +
					breakoutItem.display +
					'</div>';

				// Add threshold data
				if (threshold && showThreshold) {
					value +=
						'<div class="label-after-value label-long no-wrap">' +
						translations['Over under threshold'] +
						':' +
						'</div>';

					if (overThreshold) {
						value +=
							'<div class="over-threshold no-wrap">' +
							translations['Over by'] +
							' ' +
							overThresholdPercent +
							'%' +
							', ' +
							thresholdDifferenceFormatted +
							(partialRange ? '*' : '') +
							'</div>';
					} else if (atThreshold) {
						value +=
							'<div class="at-threshold no-wrap">' +
							translations['At threshold'] +
							', ' +
							thresholdDifferenceFormatted +
							(partialRange ? '*' : '') +
							'</div>';
					} else {
						value +=
							'<div class="under-threshold no-wrap">' +
							translations['Under by'] +
							' ' +
							overThresholdPercent +
							'%' +
							', ' +
							thresholdDifferenceFormatted +
							(partialRange ? '*' : '') +
							'</div>';
					}

					if (partialRange) {
						value +=
							'<div class="estimated-count" style="margin-top: 5px">*' +
							translations['Includes partial ' + rangeType] +
							'</div>';
					}

					value += '</div>';
				}
				scope.breakoutTooltip = value;
				scope.breakoutTooltipTemplate =
					'<div class="tooltip tooltip-chart tooltip-large" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';
			},
			template:
				'<div ' +
				'ng-show="measureData.breakout && breakoutItem.totalCount > 0 && !measureData.combineResources" ' +
				'tool-tip="{{breakoutTooltip}}" ' +
				'template="{{breakoutTooltipTemplate}}" ' +
				'prevent-translate="true" ' +
				'enable="true" ' +
				'delay=0 ' +
				'html="true" ' +
				'data-toggle="tooltip" ' +
				'data-placement="top" ' +
				'class="breakout-footer-total" ' +
				'ng-style="{background: breakoutItem.color, color: breakoutItem.textColor}" ' +
				'>' +
				'<div class="value-container"> ' +
				'<span ng-if="breakoutItem.shortName"' +
				'class="label-after-value label-long" ' +
				'>{{breakoutItem.shortName}}: </span>' +
				'<span ' +
				'ng-bind-html="breakoutItem.totalCountFormatted" ' +
				'class="" ' +
				'></span>' +
				'</div>',
		};
	}
})();
